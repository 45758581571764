import '/src/sass/app.scss';

import Alpine from 'alpinejs';
//import focus from '@alpinejs/focus';
//import collapse from '@alpinejs/collapse';

window.Alpine = Alpine;
//Alpine.plugin(focus);
//Alpine.plugin(collapse);
Alpine.start();

// Import Swiper and its modules
import Swiper from 'swiper';
import { Pagination, Navigation, EffectFade, Autoplay } from 'swiper/modules';

// Select all slider elements on the page
var swiperElements = document.querySelectorAll(".swiper");

// Iterate over each slider element and initialize a Swiper instance
swiperElements.forEach(function (swiperElement) {
    var swiper = new Swiper(swiperElement, {
        modules: [Pagination, Navigation, EffectFade, Autoplay],
        pagination: {
            el: swiperElement.querySelector(".slider-pagination"),
            clickable: true,
        },
        navigation: {
            nextEl: swiperElement.querySelector('.slider-button-next'),
            prevEl: swiperElement.querySelector('.slider-button-prev'),
        },
        speed: 1000,
        autoplay: {
            delay: 5000,
        },
        effect: "fade",
        fadeEffect: {
            crossFade: true
        },
        on: {
            slideChangeTransitionStart: function () {
                // Slide captions
                var swiper = this;
                var slideTitle = swiper.slides[swiper.activeIndex].getAttribute("data-title");
                var slideCaptions = swiperElement.querySelector(".slide-captions");
                slideCaptions.innerHTML = `<p>${slideTitle}</p>`;
            }
        }
    });

    // Set initial caption on load
    var initialTitle = swiper.slides[swiper.activeIndex].getAttribute("data-title");
    swiperElement.querySelector(".slide-captions").innerHTML = `<p>${initialTitle}</p>`;
});

// Neighborhood Map
mapboxgl.accessToken = 'pk.eyJ1IjoiOTE5ZnVsdG9uIiwiYSI6ImNsd3F1cnQ2OTAycDcya3B2ZGU3N2FkMDUifQ.JEBrC1wbowGTHFaKHM5JPw';

const map = new mapboxgl.Map({
    container: 'map',
    style: 'mapbox://styles/919fulton/clws531jr03ry01qogxeuczzj',
    zoom: 15.15,
    maxZoom: 16,
    minZoom: 12,
    center: [-87.651056, 41.885035],
    attributionControl: false
});
map.addControl(new mapboxgl.NavigationControl());
map.on('load', () => {
    const buttons = document.querySelectorAll('.neighborhood-map-ui button');
    buttons.forEach((button) => {
        button.addEventListener('click', () => {
            const layerName = button.dataset.layer;

            // Toggle active state on the buttons
            buttons.forEach((btn) => {
                if (btn === button) {
                    btn.classList.add('active');
                } else {
                    btn.classList.remove('active');
                }
            });

            // Update the filter for the Locations layer based on the selected type
            if (layerName === 'all') {
                // Show all locations
                map.setFilter('Locations', null);
            } else {
                // Filter to only show locations of the selected type
                map.setFilter('Locations', ['==', 'Type', layerName]);
            }
        });
    });
    map.resize();
});
map.dragRotate.disable();
map.scrollZoom.disable();
map.touchZoomRotate.disableRotation();
